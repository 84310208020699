@import url('https://fonts.googleapis.com/css?family=Roboto');
@import "./style/global.scss";

#root {
  min-height: 100%;
  background: #f0f2f5;

  >.layout {
    min-height: 100%;
  }
}

.logo {
  filter: brightness(0) invert(1);
}

.site-layout-content {
  background: #fff;
  margin: 0 auto;
  padding-bottom: 20px;
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

$-margin-size: 20px !default;
$-margin-size-half: 10px !default;
$-margin-size-quarter: 5px !default;
$-padding-size: 20px !default;
$-padding-size-half: 10px !default;
$-padding-size-quarter: 5px !default;

$borders: (b: bottom,
  r: right,
  l: left,
  t: top,
);

@each $border-prefix,
$border in $borders {
  .m-#{$border-prefix} {
    margin-#{$border}: $-margin-size !important;
  }

  .m-#{$border-prefix}-h {
    margin-#{$border}: $-margin-size-half !important;
  }

  .m-#{$border-prefix}-q {
    margin-#{$border}: $-margin-size-quarter !important;
  }

  .m-#{$border-prefix}-0 {
    margin-#{$border}: 0px !important;
  }

  .p-#{$border-prefix} {
    padding-#{$border}: $-padding-size !important;
  }

  .p-#{$border-prefix}-h {
    padding-#{$border}: $-padding-size-half !important;
  }

  .p-#{$border-prefix}-q {
    padding-#{$border}: $-padding-size-quarter !important;
  }

  .p-#{$border-prefix}-0 {
    padding-#{$border}: 0px !important;
  }
}

@mixin variant($name,
  $background, $background-hover, $background-active, $background-disabled,
  $border, $border-hover, $border-active, $border-disabled,
  $text-color, $text-hover-color, $text-active-color, $text-disabled-color) {
  &.#{$name} {
    background: $background;
    color: $text-color;
    border: $border;

    &:hover {
      background: $background-hover;
      color: $text-hover-color;
      border: $border-hover;
    }

    &:active {
      background: $background-active;
      color: $text-active-color;
      border: $border-active;
    }

    &[disabled] {
      background: $background-disabled;
      color: $text-disabled-color;
      border: $border-disabled;
    }
  }
}


.ant-btn {

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.justify {
    text-align: justify;
  }

  text-shadow: 0.0625rem 0.0625rem 0 rgba(21, 41, 53, 0.24);
  border-radius: 0.125rem;
  border: 0;
  cursor: pointer;
  padding: var(--base-unit-rem);
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
    text-shadow: none;
  }

  &.small {
    height: calc(var(--base-unit-rem) * var(--small-height-factor));
    min-width: calc(var(--base-unit-rem) * 18);

    &.square {
      width: calc(var(--base-unit-rem) * var(--small-height-factor));
      min-width: calc(var(--base-unit-rem) * var(--small-height-factor));
    }
  }

  &.medium {
    height: calc(var(--base-unit-rem) * var(--medium-height-factor));
    min-width: calc(var(--base-unit-rem) * 22);

    &.square {
      width: calc(var(--base-unit-rem) * var(--medium-height-factor));
      min-width: calc(var(--base-unit-rem) * var(--medium-height-factor));
    }
  }

  &.large {
    height: calc(var(--base-unit-rem) * var(--large-height-factor));
    min-width: calc(var(--base-unit-rem) * 24);

    &.square {
      width: calc(var(--base-unit-rem) * var(--large-height-factor));
      min-width: calc(var(--base-unit-rem) * var(--large-height-factor));
    }
  }

  @include variant("ant-btn-primary",
    linear-gradient(315deg, var(--primary-color-1), var(--primary-color-2)), var(--primary-color-2), var(--primary-color-2), var(--primary-color-4),
    none, none, none, none,
    var(--background-color-1), var(--background-color-1), var(--background-color-1), var(--background-color-1),
  );
  @include variant("secondary",
    none, var(--primary-color-1), var(--primary-color-3), none,
    0.0625rem solid var(--primary-color-1), none, none, 0.0625rem solid var(--primary-color-4),
    var(--primary-color-1), var(--background-color-1), var(--background-color-1), var(--primary-color-4),
  );
  @include variant("tertiary",
    none, var(--background-color-2), var(--background-color-2), none,
    none, none, none, none,
    var(--primary-color-1), var(--primary-color-1), var(--primary-color-3), var(--primary-color-4),
  );

  &.white {
    @include variant("primary",
      var(--background-color-1), var(--secondary-color-2), var(--secondary-color-2), var(--background-color-1),
      none, none, none, none,
      var(--primary-color-1), var(--primary-color-1), var(--primary-color-3), var(--primary-color-4),
    );
    @include variant("secondary",
      none, var(--background-color-1), var(--background-color-1), none,
      0.0625rem solid var(--background-color-1), none, none, 0.0625rem solid var(--primary-color-4),
      var(--background-color-1), var(--primary-color-1), var(--primary-color-3), var(--primary-color-4),
    );
    @include variant("tertiary",
      none, var(--primary-color-1), var(--primary-color-3), none,
      none, none, none, none,
      var(--background-color-1), var(--background-color-1), var(--background-color-1), var(--primary-color-4),
    );
  }

  &.alert-light {
    @include variant("primary",
      var(--error-color), var(--accent-color-1), var(--error-color), var(--text-color-inverse-2),
      none, none, none, none,
      var(--background-color-1), var(--background-color-1), var(--background-color-1), var(--background-color-1),
    );
    @include variant("secondary",
      none, var(--accent-color-1), var(--error-color), none,
      0.0625rem solid var(--error-color), none, none, 0.0625rem solid var(--text-color-inverse-2),
      var(--error-color), var(--background-color-1), var(--background-color-1), var(--text-color-inverse-2),
    );
    @include variant("tertiary",
      none, var(--accent-color-1), var(--error-color), none,
      none, none, none, none,
      var(--error-color), var(--background-color-1), var(--background-color-1), var(--text-color-inverse-2),
    );
  }

  &.alert-dark {
    @include variant("primary",
      var(--background-color-1), var(--accent-color-1), var(--error-color), var(--background-color-1),
      none, none, none, none,
      var(--error-color), var(--background-color-1), var(--background-color-1), var(--text-color-inverse-2),
    );
    @include variant("secondary",
      none, var(--accent-color-1), var(--error-color), none,
      0.0625rem solid var(--error-color), none, none, 0.0625rem solid var(--text-color-inverse-2),
      var(--error-color), var(--background-color-1), var(--background-color-1), var(--text-color-inverse-2),
    );
    @include variant("tertiary",
      none, var(--accent-color-1), var(--error-color), none,
      none, none, none, none,
      var(--error-color), var(--background-color-1), var(--background-color-1), var(--text-color-inverse-2),
    );
  }
}