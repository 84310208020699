.carousel {
  & .image {
    width: 100%;
    color: #fff;
    line-height: 460px;
    text-align: center;

    background: rgb(240, 242, 245);

    & img {
      margin: 10px auto;
      max-width: 100%;
    }
  }

  & button {
    background: #364d79 !important;
  }
}
