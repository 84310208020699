:root {
    --primary-color-1: #0090D1; // color primary text | hover border
    --primary-color-2: #00C3EA;
    --primary-color-3: #005075;
    --primary-color-4: #C9D9E8;

    --secondary-color-1: #DEF0F7; // element active
    --secondary-color-2: #B9E6F6;

    --background-color-1: #FFFFFF;
    --background-color-2: #F2F5F8;
    --background-color-3: #E4EAF0;

    --text-color-1: #152935;
    --text-color-2: #5A6872;
    --text-color-3: #9DA6AD;
    --text-color-4: #005075; // titles

    --text-color-inverse-3: #C1C1C4;
    --text-color-inverse-2: #D8D8D9;
    --text-color-inverse-1: #FAFBFD;

    --success-color: #09822A;
    --warning-color: #FDB927;
    --error-color: #D82829;

    --accent-color-1: #FC4E3D;
    --accent-color-2: #DA2C38;
    --accent-color-3: #711423;
    --accent-color-4: #00AA00;
    --accent-color-5: #0D6C80;
    --accent-color-6: #7745EC;
    --accent-color-7: #C53090;
    --accent-color-8: #753F83;
    --accent-color-9: #361D5B;

    --black-color: #000000;

    --shadow-color: rgba(21, 41, 53, 0.32);

    --base-unit: 8px;
    --base-unit-rem: 0.5rem;

    --small-height-factor: 4;
    --medium-height-factor: 5;
    --large-height-factor: 6;

    --small-height-widget-factor: 0.8;
    --medium-height-widget-factor: 1;
    --large-height-widget-factor: 1.2;


    --small-pagination-font-size-factor: 1;
    --medium-pagination-font-size-factor: 2;
    --large-pagination-font-size-factor: 3;


    --factor-bars: 8;

    //info: align the values in components ts-pop-over if change z-index-widget
    --z-index-widget-normal: 250;
    --z-index-widget-up-normal: 500;
    --z-index-widget-pagetitle: 600;
    --z-index-widget-down-bars: 750;
    --z-index-widget-popover-down: 900;
    --z-index-widget-bars: 1000;
    --z-index-widget-popover-up: 1500;
    --z-index-widget-drawer: 2000;
    --z-index-widget-tooltip: 2500;
    --z-index-widget-loader: 3000;
    --z-index-widget-shadowarea: 4950;
    --z-index-widget-menu: 5000;

}