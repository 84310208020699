@import "./reset";
@import "./variables";

html,
body {
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.container {
    padding: 0 15px;
}